import _ from "lodash"
import md5 from "md5"
import { Order } from "../entities/Order/Order"
import { OrderEvent } from "../entities/Order/OrderEvent"
import { CategoryItem, Modifier, ModifierItem } from "src/entities/Category/Category"
import { SubOrder } from "src/entities/Order/SubOrder/SubOrder"

export const HashAllOrderItem = (item: CategoryItem): string => {
  // Start with the base string
  let hashString = `${item.itemId}${item.soldByWeight}`

  // If there are no modifiers, return the hash directly
  if (item.modifiers.length === 0) return md5(hashString)

  // Sort and process the modifiers and their items
  item.modifiers
    .sort((a, b) => a.id.localeCompare(b.id))  // Sort modifiers by ID
    .forEach((modifier: Modifier) => {
      hashString += modifier.id

      modifier.modifierItems
        .sort((a, b) => a.id.localeCompare(b.id))  // Sort modifier items by ID
        .forEach((modItem: ModifierItem) => {
          hashString += modItem.id + modItem.quantity
        })
    })

  return md5(hashString)
}

export const HashOrder = (order: Order): string => {
  const hashString: string[] = [order.info.references.id, order.info.customerId ? order.info.customerId : ''];

  // Sort subOrders first (if needed)
  order.subOrders.forEach((subOrder: SubOrder) => {
    hashString.push(subOrder.id);

    // Sort subOrder items by sortPriority
    const sortedItems = [...subOrder.items].sort((a, b) => a.sortPriority - b.sortPriority);

    sortedItems.forEach((item: CategoryItem) => {
      hashString.push(`${item.itemId}${item.itemTypeId}${item.quantity}`);

      // Sort modifiers
      const sortedModifiers = [...item.modifiers].sort((a, b) => a.sortPriority - b.sortPriority);

      sortedModifiers.forEach((modifier: Modifier) => {
        hashString.push(modifier.id);

        // Sort modifier items
        const sortedModifierItems = [...modifier.modifierItems].sort(
          (a, b) => a.sortPriority - b.sortPriority
        );

        sortedModifierItems.forEach((modItem: ModifierItem) => {
          hashString.push(`${modItem.id}${modItem.type}${modItem.quantity}`);
        });
      });
    });
  });

  const combinedHashString = hashString.join('');
  return md5(combinedHashString);
};

export const ItemPriceByCategory = (item: CategoryItem): number => {
  if (!item) return 0
  let total = item.price

  if (item.modifiers) item.modifiers.forEach((modifier) => {
    const filteredItems = _.filter(modifier.modifierItems, function (item) {
      return item.event !== OrderEvent.Remove
    })
    total += _.sumBy(filteredItems, 'price')
  })

  return total
}

export const getDateDiff = (fromDate: Date, toDate: Date) => {

  const convertedFromDate = new Date(fromDate)
  const convertedToDate = new Date(toDate)
  if (convertedFromDate.getDay() !== convertedToDate.getDay()) return convertedFromDate.toLocaleDateString()

  const dateDiff = convertedFromDate.getTime() - convertedToDate.getTime()
  const min = Math.round(dateDiff / 60000)

  if (min > 90) return `${Math.round((min / 60))} hours ago`
  else return `${Math.round(min)} min ago`
}

export const parseCreationDate = (creationDate: Date) => {
  const convertedDate = new Date(creationDate)
  if (convertedDate.getDay() !== new Date().getDay()) return convertedDate.toLocaleDateString()
  return convertedDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
}

export const formatOrderNo = (orderNo: number | null) => {
  const length = process.env.REACT_APP_ORDER_NO_LENGTH ? parseInt(process.env.REACT_APP_ORDER_NO_LENGTH) : 0
  if (orderNo) return String(orderNo).padStart(length, '0')
  else return "-"
}

export const nextModifierItemSortPriority = (orderItem: CategoryItem | undefined, modifiers: Modifier[]): number => {
  let sortPriority = 0

  if (orderItem) orderItem.modifiers.forEach((modifier) => {
    const maxValue: ModifierItem | undefined = _.maxBy(modifier.modifierItems, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  modifiers.forEach((modifier) => {
    const maxValue: ModifierItem | undefined = _.maxBy(modifier.modifierItems, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  return sortPriority + 1
}

export const nextModifierSortPriority = (orderItem: CategoryItem | undefined, modifiers: Modifier[]): number => {
  let sortPriority = 0

  if (orderItem) orderItem.modifiers.forEach((modifier) => {
    const maxValue: Modifier | undefined = _.maxBy<number>(modifier, 'sortPriority')
    if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority
  })

  const maxValue: Modifier | undefined = _.maxBy<number>(modifiers, 'sortPriority')
  if (maxValue) sortPriority = sortPriority > maxValue.sortPriority ? sortPriority : maxValue.sortPriority

  return sortPriority + 1
}

export const formatPhoneNumber = (mobile: string): string => {
  if (/^(069|068|067|35569|35568|35567|\+35569|\+35568|\+35567)+/.test(mobile)) {
    if (mobile.startsWith("+355")) mobile = mobile.slice(4)
    if (mobile.startsWith("355")) mobile = mobile.slice(3)
    if (mobile.startsWith("06")) mobile = mobile.slice(1)

    return mobile
  }

  return ''
}


export function getCookie(cname: string) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}
