import { waiterStorage } from "./localforageInstances"
import Waiter from "src/entities/Waiter/Waiter"

export const getWaiterLocally = async (waiterId: string): Promise<Waiter | null> => {
    return await waiterStorage.getItem<Waiter>(waiterId)
}

export const saveWaiterLocally = async (area: Waiter): Promise<void> => {
    await waiterStorage.setItem(area.id.toString(), area)
}

export const getWaitersLocally = async (): Promise<Waiter[]> => {
    const areas: Waiter[] = []
    await waiterStorage.iterate((value: Waiter, key: string) => {
        areas.push(value)
    })
    return areas
}

export const saveWaitersLocally = async (waiters: Waiter[]) => {
    waiters.forEach(async (waiter) => {
        await waiterStorage.setItem(waiter.id.toString(), waiter)
    })
}

export const waiterValidatePinLocally = async (pin: String): Promise<boolean> => {
    const waiters = await getWaitersLocally()
    const waitersFound = waiters.findIndex(x => x.pin === pin)

    return waitersFound > -1
}

export const getWaiterByPinLocally = async (pin: String): Promise<Waiter | undefined> => {
    const waiters = await getWaitersLocally()
    return waiters.find(x => x.pin === pin)
}


export const deleteWaiterLocally = async () => {
    await waiterStorage.clear()
}
