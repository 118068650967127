import { Area } from "src/entities/Area/Area"
import { areaStorage } from "./localforageInstances"

export const getAreaLocally = async (areaId: string): Promise<Area | null> => {
    return await areaStorage.getItem<Area>(areaId)
}

export const saveAreaLocally = async (area: Area): Promise<void> => {
    await areaStorage.setItem(area.id.toString(), area)
}

export const deleteAraeLocally = async () => {
    await areaStorage.clear()
}

export const getAreasLocally = async (): Promise<Area[]> => {
    const areas: Area[] = []
    await areaStorage.iterate((value: Area, key: string) => {
        areas.push(value)
    })
    return areas
}

export const saveAreasLocally = async (areas: Area[]) => {
    areas.forEach(async (area) => {
        await areaStorage.setItem(area.id.toString(), area)
    })
}

export const updateTableStatusLocally = async (tableId: number | null, status: number, orderId: string | null): Promise<boolean> => {
    if (!tableId) return false
    await areaStorage.iterate(async (value: Area, key: string) => {
        const tableFound = value.tables.find(x => x.id === tableId)
        if (tableFound) {
            tableFound.statusId = status
            tableFound.orderId = orderId
            await saveAreaLocally(value)
            return true
        }
    })

    return false
}