import { v4 as guid } from 'uuid'

const orderListReducer = (state: string = '', action) => {
    switch (action.type) {

        case "RELOAD_ORDER_LIST":
            return guid()

        default:
            return state
    }
}

export default orderListReducer