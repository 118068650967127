import axiosInstance from '../AxiosInstance'
import Settings from "src/entities/Settings/Settings"

export const getSettings = async (): Promise<Settings[]> => {
  try {
    const response = await axiosInstance.get<Settings[]>(`/settings/section/non-system`)
    return response.data
  } catch (error) {
    return []
  }
}