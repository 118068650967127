import axios from "axios"
import { getCookie } from "./utility/Helper"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_POS_API_URL
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getCookie(process.env.REACT_APP_POS_COOKIE!)}`
  config.headers.Channel = process.env.REACT_APP_POS_CHANNEL

  return config
})

export default axiosInstance